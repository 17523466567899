import React, { useState } from "react"
import { Accordion, Col, Container, Row } from "react-bootstrap";

// Styles
import './CharityStories.scss';

// Components
import SectionWrapper from "components/SectionWrapper";
import SectionHeader from "components/SectionHeader";
import CustomButton from "components/CustomButton";

const accordionList = [
    {
        id: 1,
        heading: '2021 Charity Stories',
        body: {
            logoSource: 'logo-1.png',
            title: "Residential Land continue support of the Variety Club Children's Charity",
            content: "Residential Land continue to support the Variety Club Children's Charity who have been raising money for sick, disabled, and disadvantaged children for over 60 years...<a href='#'>View More</a>"
        }
    },
    {
        id: 2,
        heading: '2020 Charity Stories',
        body: {
            logoSource: 'logo-1.png',
            title: "Residential Land continue support of the Variety Club Children's Charity",
            content: "Residential Land continue to support the Variety Club Children's Charity who have been raising money for sick, disabled, and disadvantaged children for over 60 years...<a href='#'>View More</a>"
        }
    },
    {
        id: 3,
        heading: '2019 Charity Stories',
        body: {
            logoSource: 'logo-1.png',
            title: "Residential Land continue support of the Variety Club Children's Charity",
            content: "Residential Land continue to support the Variety Club Children's Charity who have been raising money for sick, disabled, and disadvantaged children for over 60 years...<a href='#'>View More</a>"
        }
    },
    {
        id: 4,
        heading: '2018 Charity Stories',
        body: {
            logoSource: 'logo-1.png',
            title: "Residential Land continue support of the Variety Club Children's Charity",
            content: "Residential Land continue to support the Variety Club Children's Charity who have been raising money for sick, disabled, and disadvantaged children for over 60 years...<a href='#'>View More</a>"
        }
    },
];

const CharityStories = ( { charityStoriesData } ) => {

    const [ selectedItem, setSelectedItem ] = useState( accordionList[ 0 ] )

    const sectionHeaderData = {
        Title: 'Charity Stories'
    };

    return (
        <SectionWrapper
            className="charity-stories-wrapper"
            id="charityStories"
        >
            <Container>
                <Row>
                    <Col
                        className="charity-stories-content"
                        lg="8"
                    >
                        <div className="charity-stories-header">
                            <SectionHeader
                                sectionHeaderData={ sectionHeaderData }
                            />
                        </div>
                        <div className="charity-stories-body">
                            <p className="highlight-text">
                                Residential Land are committed to supporting a number of charitable causes both abroad and locally.
                            </p>
                            <p>
                                Whilst our business goes from strength to strength, it is important that we are able to give back to those less fortunate and to organisations that directly benefit others. We support a wide range of charities, from large international foundations that help thousands of people across the globe, to smaller local projects that benefit the areas of London in which we operate.
                            </p>
                            <p className="mb-0">
                                Below you can read about some of the worthy causes Residential Land has recently been involved with:
                            </p>
                            <div className="charity-stories-accordion">
                                <Accordion defaultActiveKey="0">
                                    {
                                        accordionList.map( ( accordionListItem, accordionListIndex ) => {
                                            return (
                                                <AccordionItem
                                                    key={ accordionListIndex }
                                                    accordionItemData={ accordionListItem }
                                                    accordionListIndex={ accordionListIndex }
                                                    selectedItem={ selectedItem }
                                                    setSelectedItem={ setSelectedItem }
                                                />
                                            )
                                        } )
                                    }
                                </Accordion>
                            </div>
                        </div>
                    </Col >
                    <Col className="charity-stories-sidebar">
                        <h4>
                            If you would like to find out about renting in London with Resi.Land, get in touch today.
                        </h4>
                        <div className="phone">
                                <div className="icon-image">
                                    <img
                                        src={ require( `../../../images/phone-icon.png` ).default }
                                        width="auto"
                                        height="auto"
                                        alt="Company Logo"
                                    />
                                </div>
                                <p className="mb-0">
                                    +44 (0)20 7408 5155
                                </p>
                            </div>
                        <div className="contact-information">
                            <div className="location">
                                <div className="icon-image">
                                    <img
                                        src={ require( `../../../images/location-icon.png` ).default }
                                        width="auto"
                                        height="auto"
                                        alt="Company Logo"
                                    />
                                </div>
                                <p className="mb-0">
                                    59-60 Grosvenor Street, Mayfair, <br />
                                    London, W1K 3HZ
                                </p>
                            </div>
                            <div className="phone">
                                <div className="icon-image">
                                    <img
                                        src={ require( `../../../images/phone-icon.png` ).default }
                                        width="auto"
                                        height="auto"
                                        alt="Company Logo"
                                    />
                                </div>
                                <p className="mb-0">
                                    +44 (0)20 7408 5155
                                </p>
                            </div>
                        </div>
                        <div className="hide-on-tab-and-mobile">
                            <CustomButton
                                variant="green"
                                value="REQUEST A CALLBACK"
                            />
                        </div>
                    </Col>
                </Row >
            </Container >
        </SectionWrapper >
    )
}

export default CharityStories

const AccordionItem = ( data ) => {

    const { accordionItemData, accordionListIndex, selectedItem, setSelectedItem } = data;
    const { id, heading, body = {} } = accordionItemData;
    const { logoSource, title, content } = body;

    const isSelectedItem = id === selectedItem.id;
    const iconSource = isSelectedItem ? 'minus-icon-black.png' : 'plus-icon-black.png';

    return (
        <div className="accordion-item">
            <Accordion.Toggle
                eventKey={ String( accordionListIndex ) }
                onClick={ () => isSelectedItem ? setSelectedItem( {} ) : setSelectedItem( accordionItemData ) }
                className="accordion-header"
            >
                <span>
                    { heading }
                </span>
                <span>
                    <img
                        src={ require( `../../../images/${ iconSource }` ).default }
                        width="auto"
                        height="auto"
                        alt="Plus Icon"
                    />
                </span>
            </Accordion.Toggle>
            <Accordion.Collapse
                eventKey={ String( accordionListIndex ) }
                className="accordion-body"
            >
                <div className="accordion-content">
                    <div className="charity-story-logo">
                        <img
                            src={ require( `../../../images/accordion-logo/${ logoSource }` ).default }
                            width="auto"
                            height="auto"
                            alt="Company Logo"
                        />
                    </div>
                    <div className="charity-story-content">
                        <p className="highlight-text">
                            { title }
                        </p>
                        <p
                            className="mb-0"
                            dangerouslySetInnerHTML={ {
                                __html: content
                            } }
                        />
                    </div>
                </div>
            </Accordion.Collapse>
        </div>
    )
}
